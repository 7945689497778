import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _50a2553c = () => interopDefault(import('../pages/atendente/index.vue' /* webpackChunkName: "pages/atendente/index" */))
const _be9f46a4 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _0083e1bd = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _99279cb2 = () => interopDefault(import('../pages/lite.vue' /* webpackChunkName: "pages/lite" */))
const _59a5857e = () => interopDefault(import('../pages/lite/messaging/new/_phone.vue' /* webpackChunkName: "pages/lite/messaging/new/_phone" */))
const _57613532 = () => interopDefault(import('../pages/lite/messaging/_uuid.vue' /* webpackChunkName: "pages/lite/messaging/_uuid" */))
const _3d801bbd = () => interopDefault(import('../pages/livechat.vue' /* webpackChunkName: "pages/livechat" */))
const _1d058048 = () => interopDefault(import('../pages/livechat/_uuid.vue' /* webpackChunkName: "pages/livechat/_uuid" */))
const _e1421a7a = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _21b9b24a = () => interopDefault(import('../pages/messaging.vue' /* webpackChunkName: "pages/messaging" */))
const _24d985bc = () => interopDefault(import('../pages/messaging/new/_phone.vue' /* webpackChunkName: "pages/messaging/new/_phone" */))
const _40218634 = () => interopDefault(import('../pages/messaging/_uuid.vue' /* webpackChunkName: "pages/messaging/_uuid" */))
const _1164806c = () => interopDefault(import('../pages/old_onboarding.vue' /* webpackChunkName: "pages/old_onboarding" */))
const _61597952 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _ea111d40 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _04c4d0ce = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _67f3c84c = () => interopDefault(import('../pages/auth/forgot-password/index.vue' /* webpackChunkName: "pages/auth/forgot-password/index" */))
const _27314e03 = () => interopDefault(import('../pages/auth/signin/index.vue' /* webpackChunkName: "pages/auth/signin/index" */))
const _a563180e = () => interopDefault(import('../pages/auth/signup/index.vue' /* webpackChunkName: "pages/auth/signup/index" */))
const _1038a728 = () => interopDefault(import('../pages/broadcast/new/index.vue' /* webpackChunkName: "pages/broadcast/new/index" */))
const _bef2d730 = () => interopDefault(import('../pages/login/forgot_password.vue' /* webpackChunkName: "pages/login/forgot_password" */))
const _5a5f762e = () => interopDefault(import('../pages/login/page.vue' /* webpackChunkName: "pages/login/page" */))
const _55d554af = () => interopDefault(import('../pages/register/free.vue' /* webpackChunkName: "pages/register/free" */))
const _1c2f7288 = () => interopDefault(import('../pages/subscription/change/index.vue' /* webpackChunkName: "pages/subscription/change/index" */))
const _3e681b0f = () => interopDefault(import('../pages/zap/attention.vue' /* webpackChunkName: "pages/zap/attention" */))
const _2a89c44a = () => interopDefault(import('../pages/zap/benefits.vue' /* webpackChunkName: "pages/zap/benefits" */))
const _6b1db9f2 = () => interopDefault(import('../pages/zap/first_access.vue' /* webpackChunkName: "pages/zap/first_access" */))
const _4da821d2 = () => interopDefault(import('../pages/zap/ImportContacts.vue' /* webpackChunkName: "pages/zap/ImportContacts" */))
const _54a83184 = () => interopDefault(import('../pages/zap/qrcode_v2.vue' /* webpackChunkName: "pages/zap/qrcode_v2" */))
const _427b37aa = () => interopDefault(import('../pages/zap/templates.vue' /* webpackChunkName: "pages/zap/templates" */))
const _8b7e0dae = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f2e38628 = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "pages/index/index" */))
const _aa5f474a = () => interopDefault(import('../pages/index/broadcast.vue' /* webpackChunkName: "pages/index/broadcast" */))
const _7d124c05 = () => interopDefault(import('../pages/index/configuracao.vue' /* webpackChunkName: "pages/index/configuracao" */))
const _087a139e = () => interopDefault(import('../pages/index/leads.vue' /* webpackChunkName: "pages/index/leads" */))
const _481c569b = () => interopDefault(import('../pages/index/myaccount.vue' /* webpackChunkName: "pages/index/myaccount" */))
const _80b38154 = () => interopDefault(import('../pages/index/sequencia.vue' /* webpackChunkName: "pages/index/sequencia" */))
const _434ca624 = () => interopDefault(import('../pages/index/chat/messaging/_uuid.vue' /* webpackChunkName: "pages/index/chat/messaging/_uuid" */))
const _26d0554e = () => interopDefault(import('../pages/register/auth/_token.vue' /* webpackChunkName: "pages/register/auth/_token" */))
const _749fb3c0 = () => interopDefault(import('../pages/zap/simulator/_id.vue' /* webpackChunkName: "pages/zap/simulator/_id" */))
const _dbbd591c = () => interopDefault(import('../pages/qrcode/_token.vue' /* webpackChunkName: "pages/qrcode/_token" */))
const _f6a2ad6a = () => interopDefault(import('../pages/recovery/_token.vue' /* webpackChunkName: "pages/recovery/_token" */))
const _59d4a57d = () => interopDefault(import('../pages/register/_token.vue' /* webpackChunkName: "pages/register/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/atendente",
    component: _50a2553c,
    name: "atendente"
  }, {
    path: "/dashboard",
    component: _be9f46a4,
    name: "dashboard"
  }, {
    path: "/help",
    component: _0083e1bd,
    name: "help"
  }, {
    path: "/lite",
    component: _99279cb2,
    name: "lite",
    children: [{
      path: "messaging/new/:phone?",
      component: _59a5857e,
      name: "lite-messaging-new-phone"
    }, {
      path: "messaging/:uuid?",
      component: _57613532,
      name: "lite-messaging-uuid"
    }]
  }, {
    path: "/livechat",
    component: _3d801bbd,
    name: "livechat",
    children: [{
      path: ":uuid?",
      component: _1d058048,
      name: "livechat-uuid"
    }]
  }, {
    path: "/login",
    component: _e1421a7a,
    name: "login"
  }, {
    path: "/messaging",
    component: _21b9b24a,
    name: "messaging",
    children: [{
      path: "new/:phone?",
      component: _24d985bc,
      name: "messaging-new-phone"
    }, {
      path: ":uuid?",
      component: _40218634,
      name: "messaging-uuid"
    }]
  }, {
    path: "/old_onboarding",
    component: _1164806c,
    name: "old_onboarding"
  }, {
    path: "/onboarding",
    component: _61597952,
    name: "onboarding"
  }, {
    path: "/payment",
    component: _ea111d40,
    name: "payment"
  }, {
    path: "/subscription",
    component: _04c4d0ce,
    name: "subscription"
  }, {
    path: "/auth/forgot-password",
    component: _67f3c84c,
    name: "auth-forgot-password"
  }, {
    path: "/auth/signin",
    component: _27314e03,
    name: "auth-signin"
  }, {
    path: "/auth/signup",
    component: _a563180e,
    name: "auth-signup"
  }, {
    path: "/broadcast/new",
    component: _1038a728,
    name: "broadcast-new"
  }, {
    path: "/login/forgot_password",
    component: _bef2d730,
    name: "login-forgot_password"
  }, {
    path: "/login/page",
    component: _5a5f762e,
    name: "login-page"
  }, {
    path: "/register/free",
    component: _55d554af,
    name: "register-free"
  }, {
    path: "/subscription/change",
    component: _1c2f7288,
    name: "subscription-change"
  }, {
    path: "/zap/attention",
    component: _3e681b0f,
    name: "zap-attention"
  }, {
    path: "/zap/benefits",
    component: _2a89c44a,
    name: "zap-benefits"
  }, {
    path: "/zap/first_access",
    component: _6b1db9f2,
    name: "zap-first_access"
  }, {
    path: "/zap/ImportContacts",
    component: _4da821d2,
    name: "zap-ImportContacts"
  }, {
    path: "/zap/qrcode_v2",
    component: _54a83184,
    name: "zap-qrcode_v2"
  }, {
    path: "/zap/templates",
    component: _427b37aa,
    name: "zap-templates"
  }, {
    path: "/",
    component: _8b7e0dae,
    children: [{
      path: "",
      component: _f2e38628,
      name: "index"
    }, {
      path: "broadcast",
      component: _aa5f474a,
      name: "index-broadcast"
    }, {
      path: "configuracao",
      component: _7d124c05,
      name: "index-configuracao"
    }, {
      path: "leads",
      component: _087a139e,
      name: "index-leads"
    }, {
      path: "myaccount",
      component: _481c569b,
      name: "index-myaccount"
    }, {
      path: "sequencia",
      component: _80b38154,
      name: "index-sequencia"
    }, {
      path: "chat/messaging/:uuid?",
      component: _434ca624,
      name: "index-chat-messaging-uuid"
    }]
  }, {
    path: "/register/auth/:token?",
    component: _26d0554e,
    name: "register-auth-token"
  }, {
    path: "/zap/simulator/:id?",
    component: _749fb3c0,
    name: "zap-simulator-id"
  }, {
    path: "/qrcode/:token?",
    component: _dbbd591c,
    name: "qrcode-token"
  }, {
    path: "/recovery/:token?",
    component: _f6a2ad6a,
    name: "recovery-token"
  }, {
    path: "/register/:token?",
    component: _59d4a57d,
    name: "register-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
