export const state = () => ({
  zap: undefined,
  session: {
    id: undefined,
    phone: undefined,
  },
  status: {
    is_logged_in: undefined,
    is_connected: undefined,
    version: undefined,
    is_alive: undefined,
    is_timer: undefined
  },
  profile: undefined /* {
    profilePic: undefined,
    profilePicPreview: undefined,
    businessInfo: undefined,
    status: undefined
  }*/,
  server: undefined,
  qrcode: {
    url: '/zap/qrcode_v2',
    route: 'zap-qrcode_v2'
  },
  coordinator_url: process.env.API_COORDINATOR_URL || "https://coordinator-api.clickzap.io/api/v1",
  coordinator_events_url: process.env.API_COORDINATOR_events_URL || "https://coordinator-events-api.clickzap.io/api/v1",
})

export const mutations = {
  set(state, zap) {
    state.zap = zap
  },
  setStatus(state, status) {
    state.status = status
  },
  setProfile(state, profile) {
    state.profile = profile
  },
  clearStatus(state) {
    state.status = undefined
  },
  setServer(state, server) {
    state.server = server
  },
  setQrcode(state, qrcode) {
    state.qrcode = qrcode
  },

  // session mutations
  setSession(state, { id, phone }) {
    state.session = { id, phone }
  },
}

export const actions = {
  async get({ commit }, options = undefined) {
    const resp = await this.$axios.request({
      url: 'machines/',
      params: {
        fields: options?.fields
      },
      headers: {
        'content-type': 'application/json'
      }
    })
    const zap = resp.data.length >= 1 ? resp.data[0] : undefined
    commit('set', zap)
    return zap
  },
  async create({ commit }, data) {
    const resp = await this.$axios.post('machines/', data)
    commit('set', resp.data)
  },
  async getServer({ commit }) {
    if (state.zap) {
      const resp = await this.$axios.request({
        url: `/machines/${state.zap.id}/server`,
        headers: {
          'content-type': 'application/json'
        }
      })
      return resp.data.host
    }
    return null
  },
  async getMyServer({ commit, state }) {
    if (state.zap) {
      const resp = await this.$axios.request({
        url: `/machines/${state.zap.id}/my_server`,
        headers: {
          'content-type': 'application/json'
        }
      })
      commit('setServer', resp.data.host)
      return resp.data.host
    }
    return null
  },
  async getQrCode({ commit, state }) {
    if (state.zap) {
      const resp = await this.$axios.request({
        url: `/machines/${state.zap.id}/qr_code`,
        headers: {
          'content-type': 'application/json'
        }
      })
      if (typeof resp.data.status !== 'string') {
        commit('setStatus', resp.data.status)
      }
      return resp
    }
    return null
  },
  async reserveDriver({ commit, state }) {
    if (state.zap) {
      const resp = await this.$axios.request({
        url: `/machines/${state.zap.id}/reserve_driver`,
        headers: {
          'content-type': 'application/json'
        }
      })
      if (typeof resp.data.status !== 'string') {
        commit('setStatus', resp.data.status)
      }
      return resp
    }
    return null
  },
  async requestDriver({ commit, state, rootGetters }, phone) {
    if (state.zap) {
      const resp = await this.$axios.request({
        method: 'post',
        baseURL: state.coordinator_url,
        url: `/drivers/sessions/request/`,
        data: {
          "machine_id": state.zap.id.toString(),
          "machine_data": {
            user: {
              id: rootGetters.loggedUser?.id,
              name: rootGetters.loggedUser?.name,
              email: rootGetters.loggedUser?.email,
            },
            machine: {
              id: state.zap.id,
              name: state.zap.name
            }
          },
          ...(phone && { "pairing_code_phone_number": phone })
        },
        headers: {
          'content-type': 'application/json'
        }
      })
      const status = resp.data.status || resp.data.detail?.status
      if (typeof status !== 'string') {
        commit('setStatus', status)
      }
      return resp
    }
    return null
  },
  async getLatestQrcode({ commit, state }, sessionId) {
    if (!sessionId) return

    return this.$axios.request({
      method: 'get',
      baseURL: state.coordinator_url,
      url: `/drivers/sessions/${sessionId}/qrcode`,
      headers: { 'content-type': 'application/json' }
    }).then(({ data }) => data)
  },
  async getLastSyncEvent({ commit, state }) {
    if (!state.session.id) return

    const { data } = await this.$axios.request({
      method: 'get',
      baseURL: state.coordinator_url,
      url: `/drivers/sessions/${state.session.id}/sync`,
      headers: { 'content-type': 'application/json' }
    })

    return data?.sync_info
  },
  async sendCodeByEmail({ }, email) {
    if (!email) return

    return this.$axios.post('/qrcode/send', { email })
  },
  async sendCodeByEmail({ }, email) {
    if (!email) return

    return this.$axios.post('/qrcode/send', { email })
  },
  async initDevice({ commit, state }, deviceId) {
    if (state.zap) {
      const resp = await this.$axios.request({
        method: 'post',
        baseURL: state.coordinator_url,
        url: `/drivers/devices/${deviceId}`,
        data: {
          "machine_id": state.zap.id.toString()
        },
        headers: {
          'content-type': 'application/json'
        }
      })
      return resp.data
    }
    return null
  },
  async removeDevice({ commit, state }, deviceId) {
    if (state.zap) {
      try {
        const resp = await this.$axios.request({
          method: 'delete',
          baseURL: state.coordinator_url,
          url: `/drivers/devices/${deviceId}`,
          headers: {
            'content-type': 'application/json'
          }
        })
        return resp
      } catch {
        console.log("skip delete device")
      }
    }
    return null
  },
  async getDevice({ commit, state }, deviceId) {
    if (state.zap) {
      try {
        const resp = await this.$axios.request({
          method: 'get',
          baseURL: state.coordinator_url,
          url: `/drivers/devices/${deviceId}`,
          headers: {
            'content-type': 'application/json'
          }
        })
        return resp.data
      } catch (e) {
        if ([400, 404].includes(e.resp?.status)) {
          return false
        }
      }
    }
    return null
  },
  async sendMessage({ commit, state }, { to, content }) {
    try {
      const resp = await this.$axios.request({
        method: 'post',
        baseURL: state.coordinator_url,
        url: '/drivers/messages',
        data: {
          session_id: state.status.id,
          to,
          ...content
        },
        headers: {
          'content-type': 'application/json'
        }
      })
      return resp
    } catch (e) {
      if ([400, 404].includes(e.resp?.status)) {
        return false
      }
    }
  },
  async downloadMedia({ commit, state }, message) {
    try {
      const resp = await this.$axios.request({
        method: 'post',
        baseURL: state.coordinator_url,
        url: '/drivers/messages/download',
        data: {
          session_id: state.status.id,
          message
        },
        headers: {
          'content-type': 'application/json'
        }
      })
      return resp
    } catch (e) {
      if ([400, 404].includes(e.resp?.status)) {
        return false
      }
    }
  },
  async loadStatus({ commit, state }) {
    let sessionId = undefined
    try {
      const resp = await this.$axios.request({
        baseURL: state.coordinator_url,
        url: `/drivers/sessions/status?machine_id=${state.zap.id}`,
        headers: {
          'content-type': 'application/json'
        }
      })
      commit('setStatus', {
        is_logged_in: resp.data.logged,
        is_connected: resp.data.logged,
        version: "new-version",
        ...resp.data
      })
      sessionId = resp.data.id || resp.data._id
    } catch (e) {
      commit('setStatus', {
        is_logged_in: undefined,
        is_connected: undefined,
        version: undefined
      })
      throw e
    }

    if (sessionId) {
      try {
        const resp2 = await this.$axios.request({
          baseURL: state.coordinator_url,
          url: `/drivers/sessions/${sessionId}/profile`,
          headers: {
            'content-type': 'application/json'
          }
        })
        commit('setProfile', resp2.data.profile)
      } catch (e) {
        console.log("get session profile error")
      }
    }
  },
  async loadFilter({ commit }, machineId) {
    return await this.$axios.request({
      url: `/machines/${machineId}/filters`
    })
  },
  async publish({ commit }, machineId) {
    return await this.$axios.request({
      url: `/machines/${machineId}/publish`
    })
  },
  async remove({ state }, machineId) {
    return await this.$axios.request({
      method: 'delete',
      baseURL: state.coordinator_url,
      url: `/drivers/sessions?session_id=${state.status.id}`,
    })
  },
  async set_mentoring({ commit }, [data, machineId]) {
    return await this.$axios.request({
      method: 'put',
      url: `/machines/${machineId}/mentoring`,
      data: data
    })
  },
  async removeOnboardingFeatureFlag({ commit }, machineId) {
    return await this.$axios.request({
      method: 'put',
      url: `/machines/${machineId}`,
      data: {
        feature_flag_remove: "onboarding"
      }
    })
  },
  async importContacts({ state }) {
    return await this.$axios.request({
      method: 'post',
      baseURL: state.coordinator_url,
      url: `/leads/import`,
      data: {
        machine_id: state.zap.id.toString()
      }
    })
  },
  async getProfilePic({ state }, chatId) {
    const resp = await this.$axios.request({
      method: 'post',
      baseURL: state.coordinator_url,
      url: `/drivers/contacts/profile-pic`,
      data: {
        session_id: state.status.id,
        contact_id: chatId
      }
    })
    return resp.data.status == "ok" ? resp.data.content : ""
  },
  async getWhatsappInfo({ state }, contactId) {
    const resp = await this.$axios.request({
      method: 'get',
      baseURL: state.coordinator_url,
      url: `/drivers/contacts/${state.status.id}/whatsapp-info/${contactId}`
    })
    return resp.data?.content
  },
  async registerSessionEvent({ state }, data) {
    return await this.$axios.request({
      method: 'post',
      baseURL: state.coordinator_events_url,
      url: `/events/web`,
      data: data
    })
  },
  async loadSessionEvents({ state }, cursor_next_index = undefined, page_size = 1000) {
    const resp = await this.$axios.request({
      method: 'get',
      baseURL: state.coordinator_events_url,
      url: '/events/',
      params: { session_id: state.status.id, page_size, cursor_next_index }
    })
    return resp.data
  },
  async loadSessionEventCount({ state }, cursor_next_index = undefined) {
    const resp = await this.$axios.request({
      method: 'get',
      baseURL: state.coordinator_events_url,
      url: '/events/count',
      params: { session_id: state.status.id, cursor_next_index }
    })
    return resp.data.count
  },
}
