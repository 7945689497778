<template>
  <div>
    <div 
      ref="whatsappIcon"
      style="display:none;position:fixed;bottom:80px;right:40px;background-color:#25d366;border-radius:50px;z-index:1000;"
    >
      <v-tooltip top>
        <div slot="activator">
          <a
            style="display: none; width:60px;height:60px;color:#FFF;border-radius:50px;text-align:center;font-size:30px;box-shadow: 1px 1px 2px #888;"
            :href="whatsappLink" target="_blank" @click="registerClick"
          >
            <i style="margin-top:16px" class="fab fa-whatsapp" />
          </a>
        </div>
        <span>Falar com o suporte.</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  head() {
    return {
      script: [
        {
          src: '/assets/libs/chatwoot.js'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['loggedUser']),
    isFree() {
      return this.loggedUser?.is_free
    },
    whatsappLink() {
      return 'https://czap.me/OzjoCSM7KHZ2fRRDRUc'
    }
  },
  mounted() {
    this.loadChatwootIcon()
  },
  methods: {
    async registerClick() {
      this.$mixpanel.track('Clicked whatsapp support link')
    },
    loadChatwootIcon(isRetry = false) {
      setTimeout(() => {
        if (typeof showChatwootSupport === 'undefined') {
          if (!isRetry) {
            console.warn('showChatwootSupport is not defined yet, retrying...')
            this.loadChatwootIcon(true)
          } else {
            console.error('Failed to load showChatwootSupport after retry')
          }
        } else {
          showChatwootSupport()
        }
      }, 2000)
    }
  }
}
</script>
